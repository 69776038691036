import Vuex from 'vuex';
// TODO: change this static import to an axios request to the firestore
// import createPersistedState from "vuex-persistedstate";

const myPlugin = (store) => {
  // called when the store is initialized
  store.subscribe((mutation, state) => {
    // called after every mutation.
    // The mutation comes in the format of `{ type, payload }`.
  });
};

const initialUserState = () => {
  return {
    personalInfo: {
      firstName: '',
      lastName: '',
      email: ''
    },
    employmentInfo: {
      niNumber: '',
      address: ''
    },
    pensionHistory: {
      pensionProvidersByNameEmployer: []
    },
    firefighteruser: false,
    signature: '',
    documentId: '',
    pensionbeeuser: false,
    state: {
      loggedIn: false
    },
    pensionStatus: {
      // { pensionHistorySeqNum : { }}
    },
    AVCWiseInfo: {},
    avcWiseSelection: {},
    updatedAt: '',
    createdAt: '',
    documents: [],
    shortlistedAvcFunds: [],
    tourCompleted: false,
    suezuser: false,
    checkoutPensions: [],
    checkoutSessionId: '',
    paidPensions: [],
    pensionDetails: {},
    pensionSavings: {},
    mpaa: {},
    pensionBeeInfo: {
      retirementAge: '',
      selectedPots: [],
      generateReport: false
    },
    shortlistedFund: '',
    taxLiability: {},
    pshData: {}
  };
};

const initialUserFundState = () => {
  return {
    fundList: []
  };
};

const initialNotificationState = () => {
  return {
    notifications: [],
    newNotification: 0
  };
};

const initialMedicalState = () => {
  return {
    adl: {},
    lifestyle: {},
    medicalAssetment: {},
    userId: ''
  };
};

const userStore = () => {
  return new Vuex.Store({
    strict: true,
    // plugins
    plugins: [myPlugin],
    state: {
      users: [],
      docId: '',
      providersname: ''
    },

    mutations: {},
    actions: {},

    modules: {
      namespaced: true,
      providersRoutingvalue: {
        state: {
          employmentRouteQueryParams: '',
          providersname: {}
        },
        mutations: {
          addRouteValue(state, resultRouteQuery) {
            state.employmentRouteQueryParams = resultRouteQuery;
          },
          updatePensionProviders(state, resultupdatePensionProviders) {
            state.providersname = Object.assign(
              state.providersname,
              resultupdatePensionProviders
            );
          }
        }
      },
      user: {
        state: initialUserState,
        mutations: {
          updatePersonalInfo(state, personalInfo) {
            state.personalInfo = personalInfo;
          },
          addCreationTime(state) {
            state.createdAt = new Date().toISOString();
          },
          updateCreationTime(state, time) {
            // update creation time in current state
            state.createdAt = time;
          },
          addUpdationTime(state) {
            state.updatedAt = new Date().toISOString();
          },
          updateDocumentId(state, docId) {
            state.documentId = docId;
          },
          updateEmploymentInfo(state, employmentInfo) {
            state.employmentInfo = employmentInfo;
          },
          updatePensionHistory(state, pensionHistory) {
            state.pensionHistory = pensionHistory;
          },
          addProviderByName(state, providerName) {
            state.pensionHistory.pensionProvidersByNameEmployer.push(
              providerName
            );
          },
          updatePensionProvidersByName(state, resultPensionProvidersByName) {
            state.pensionHistory.pensionProvidersByNameEmployer = resultPensionProvidersByName;
          },
          updateSignature(state, signatureData) {
            state.signature = signatureData;
          },
          authUser(state, flag) {
            state.state.loggedIn = flag;
          },
          updatePensionStatus(state, pensionStatus) {
            state.pensionStatus = pensionStatus;
          },
          updatePensionDetails(state, pensionDetails) {
            state.pensionDetails = pensionDetails;
          },
          updatePensionSavings(state, pensionSavings) {
            state.pensionSavings = pensionSavings;
          },
          updateDocuments(state, documents) {
            state.documents = documents || [];
          },
          updateCheckoutPensions(state, checkoutPensions) {
            state.checkoutPensions = checkoutPensions || [];
          },
          updateCheckoutSessionId(state, checkoutSessionId) {
            state.checkoutSessionId = checkoutSessionId || '';
          },
          updatePaidPensions(state, paidPensions) {
            state.paidPensions = paidPensions || [];
          },
          updateFireFighterStatus(state, flag) {
            state.firefighteruser = flag;
          },
          updateMpaa(state, mpaa) {
            state.mpaa = mpaa;
          },
          updateTaxLiability(state, taxLiability) {
            state.taxLiability = taxLiability;
          },
          setSuezFlag(state, flag) {
            state.suezuser = flag;
          },
          updatePensionBeeStatus(state, flag) {
            state.pensionbeeuser = flag;
          },
          updatePensionBeeInfo(state, value) {
            state.pensionBeeInfo = value;
          },
          updateShortlistedFund(state, value) {
            state.shortlistedFund = value;
          },
          setVerificationStatus(state, flag) {
            state.sendVerificationEmailAgain = flag;
          },
          setReadNextStepsFlag(state, flag) {
            state.isReadNextStepsIns = flag;
          },
          reset(state) {
            const s = initialUserState();
            Object.keys(s).forEach((key) => {
              state[key] = s[key];
            });
          },
          updateAVCWiseInfo(state, AVCWiseInfo) {
            state.AVCWiseInfo = AVCWiseInfo;
          },
          setAVCEWiseFlag(state, flag) {
            state.avcwiseuser = flag;
          },
          updateAVCWiseSelection(state, selection) {
            state.avcWiseSelection = selection;
          },
          updateShortlistedFunds(state, funds) {
            state.shortlistedAvcFunds = funds || [];
          },
          updateTourStatus(state, status) {
            state.tourCompleted = status;
          },
          setPshFlag(state, flag) {
            state.pshuser = flag;
          },
          setRmFlag(state, flag) {
            state.rmuser = flag;
          },
          updatePshData(state, pshData) {
            state.pshData = pshData;
          },
          updateDefaultReportIds(state, reportsIds) {
            state.defaultReportIds = reportsIds;
          }
        },
        getters: {
          getPensionStatus: (state) => {
            return state.pensionStatus;
          },
          getAVCWiseSelection: (state) => {
            return state.avcWiseSelection;
          },
          getShortlistedFunds: (state) => {
            return state.shortlistedAvcFunds;
          },
          getPensionBeeInfo: (state) => {
            return state.pensionBeeInfo;
          },
          getShortlistedFund: (state) => {
            return state.shortlistedFund;
          },
          getPersonalInfo: (state) => {
            return state.personalInfo;
          },
          getAuthState: (state) => {
            return state.state.loggedIn;
          }
        }
      },
      userFundList: {
        state: initialUserFundState,
        mutations: {
          setUserFunds(state, funds) {
            state.fundList = funds;
          },
          reset(state) {
            const s = initialUserFundState();
            Object.keys(s).forEach((key) => {
              state[key] = s[key];
            });
          }
        }
      },
      notifications: {
        state: initialNotificationState,
        mutations: {
          updateNotifications(state, notif) {
            state.notifications = [];
            state.newNotification = 0;
            notif.forEach((noti) => {
              if (!noti.isRead) {
                state.newNotification += 1;
              }
            });
            state.notifications = notif.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateB - dateA;
            });
          },
          readNotification(state, notiId) {
            state.newNotification = 0;
            state.notifications = state.notifications.map((noti) => {
              if (!noti.isRead) {
                noti.isRead = true;
              }
              return noti;
            });
            state.notifications = state.notifications.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateB - dateA;
            });
          },
          reset(state) {
            const s = initialNotificationState();
            Object.keys(s).forEach((key) => {
              state[key] = s[key];
            });
          }
        }
      },
      medicalHealthForm: {
        state: initialMedicalState,
        mutations: {
          updateMedicalLifestyle(state, lifestyle) {
            state.lifestyle = lifestyle;
          },
          updateMedicalAdl(state, adl) {
            state.adl = adl;
          },
          updateMedicalMA(state, medicalAssetment) {
            state.medicalAssetment = medicalAssetment;
          },
          updateUserId(state, userId) {
            state.userId = userId;
          },
          reset(state) {
            const s = initialMedicalState();
            Object.keys(s).forEach((key) => {
              state[key] = s[key];
            });
          }
        }
      },
      landingPage: {
        state: {
          currView: 'personal',
          eventEmited: ''
        },
        mutations: {
          setCurrView(state, viewName) {
            state.currView = viewName;
          }
        }
      },
      snackbar: {
        state: {
          snack: ''
        },
        mutations: {
          setSnack(state, snack) {
            state.snack = snack;
          }
        }
      }
    }
  });
};

export default userStore;
