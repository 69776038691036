export const defaultPensionLogo =
  'https://storage.googleapis.com/download/storage/v1/b/aw-onboard-test.appspot.com/o/images%2Fdefault-pension.jpg?generation=1594894259066708&alt=media';

export const allowDashboard = true;
export const disableSignUp = false;
export const disableNewPension = false;

export const surveyQuestionsList = [
  {
    question: 'How has the process of adding your pensions been so far?',
    options: [
      'Fairly easy',
      'Rather complicated',
      'I encountered technical issues',
      'I haven’t added any pensions yet'
    ]
  },
  {
    question: 'How has it been for you waiting for your scores to be released?',
    options: [
      'I had no problem waiting',
      'No strong feelings',
      'The wait has been very frustrating'
    ]
  },
  {
    question: 'Do you understand the scores you have received so far?',
    options: [
      'Yes, I understand the logic',
      'Not fully or not all of them',
      'No, I don’t really get the scoring',
      'I haven’t received any scores yet'
    ]
  },
  {
    question: 'Did you find the scores engaging?',
    options: [
      'Highly Engaging',
      'Neutral',
      'Disengaging',
      `I haven't received any scores yet`
    ]
  },
  {
    question: 'Have you received any unexpected scores?',
    options: [
      'Yes',
      'No',
      'I didn’t have any expectations',
      `I haven’t received any score yet`
    ]
  },
  {
    question: 'Is there anything else you’d like to tell us?',
    textbox: true
  }
];

export const surveyQuestionsIndex = [1, 2, 3, 4, 5, 6];

export const MONEY_ACCESS_TYPE = {
  ANNUITY: 'buy an annuity',
  TAX_FREE: 'take as tax-free cash',
  DRAWDOWN: 'draw down funds over time'
};

export const MONEY_ACCESS_TYPE_MAPPING = {
  'buy an annuity': 'ANNUITY',
  'take as tax-free cash': 'TAX_FREE',
  'draw down funds over time': 'DRAWDOWN'
};

export const RISK_CATEGORIES = {
  MEDIUM: 'Medium Risk',
  HIGHER: 'Higher Risk',
  LOWER: 'Lower Risk',
  VERY_LOW: 'Minimal Risk'
};

export const BENCHMARK_VALS = {
  GROWTH: 'Growth',
  CASH: 'Cash',
  BOND: 'Bond'
};

export const FUNDTYPE = {
  GROWTH: 'Growth',
  CASH: 'Cash',
  BOND: 'Bond',
  SPECIALIST: 'Specialist',
  CONVICTION: 'Conviction'
};

export const EMPLOYER_IDS = {
  KIRKLEES: 'aZus7sJufJIc6HwhwrH0',
  HORSHAM: '9nEmLmsnbLXpRkA0qRO9'
};
export const ajBellPensionId = 'IJmwvsrInisw4LOIozHe';
export const vanguardPensionId = 'EFI6O54YpJZS5X8aTOx8';
export const interactivePensionId = 'bIku37gg1T4fCoxG5RJ4';

export const stripeKey =
  'pk_live_51Ll7A8EjGIDj3gC2dEIuLJrykYj5mpP3aSbFrtnA4M411Wyp2maDtre6V58pLaeSErint7eBFZ3lXbhqicF1Fv5C00O0V9WNES';

export const suezSchemeScore = 53;

export const PRODUCT_CODES = {
  suez: 'agewage-suez',
  pensionbee: 'agewage-pensionbee',
  avcwise: 'agewage-avcwise',
  develop: 'agewage-develop',
  main: 'agewage-main',
  firefighter: 'agewage-firefighter',
  psh: 'agewage-psh',
  rm: 'agewage-rm'
};

export const PRODUCT_CODES_BACKEND = {
  AGEWAGE: 'agewage',
  PENSIONBEE: 'pensionbee',
  AVCWISE: 'avcwise',
  SUEZ: 'suez',
  FIREFIGHTER: 'firefighter',
  PSH: 'psh',
  RM: 'rm'
};

export const suezPensionId = 'qRGF5HkIgHeEaDuU9GOx';
