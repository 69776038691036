import axios from 'axios';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase/app';
import { logOut } from '~/plugins/utils';

const B2B_SUEZ_API_PREFIX = 'https://b2b-api.agewage.com/api/suez';
const B2B_FIREBASE_API_PREFIX = 'https://b2b-api.agewage.com/api/d2c';
const B2B_AVC_API_PREFIX = 'https://b2b-api.agewage.com/api/avcwise';
const B2B_API_PREFIX = 'https://b2b-api.agewage.com/api/public';

const API_AUTHORIZATION_HEADER =
  'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBVVRIX1RPS0VOX0ZPUl9DT01NX0JFVFdFRU5fQ0xPVURGVU5DVElPTl9BTkRfQkFDS0VORCJ9.OnRtlAIuqsec6FQimHOTMXhrniy7Zgag0upbtArEIgw';

export async function getUserStatus(user) {
  const userData = await getUserObjData(user.email);
  return userData || false;
}

export async function signInWithToken(token) {
  const result = await firebase
    .auth()
    .signInWithCustomToken(token)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {
      console.log(error, error.message);
    });
  return result;
}

export async function getUserAuthToken() {
  try {
    const authToken = await firebase.auth().currentUser.getIdToken(true);
    return authToken;
  } catch (err) {
    await sendSentryError(true);
    await logOut();
    return false;
  }
}

export async function getUserObjData(userEmail) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    const dataToSend = { email: userEmail };
    try {
      const response = await axios(`${B2B_FIREBASE_API_PREFIX}/get-user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        data: dataToSend,
        body: JSON.stringify(dataToSend)
      });
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function getUserObjDataById(userId) {
  const userData = await getUserObjData();
  return userData;
}

export const resetPasswordMail = async (userData) => {
  const apiString = `${B2B_API_PREFIX}/send-password-reset-email?email=${encodeURIComponent(
    userData.email
  )}`;
  try {
    const response = await axios(apiString, {
      method: 'GET'
    });
    if (response && response.status == 200) {
      return response.data;
    }
  } catch (err) {
    const result = await HandleAPIError(err.response.status);
    return result;
  }
};

export async function getNotificationObjData(userId) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    const dataToSend = { id: userId };
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/get-notifications`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          data: dataToSend,
          body: JSON.stringify(dataToSend)
        }
      );
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function getProvidersObjData() {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(`${B2B_FIREBASE_API_PREFIX}/get-providers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      });
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function getUserQuoteValue(params) {
  console.log(params);
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/get-user-quote`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          params
        }
      );
      if (response && response.status == 200) {
        console.log(response, '909090');
        return response.data;
      }
    } catch (err) {
      console.log(err, err.response, '90909');
      if (err.response.status == 503) {
        const alertMsg = err.response.data || 'something went wrong!';
        await window.$nuxt.$store.commit('setSnack', alertMsg);
        return false;
      } else {
        const result = await HandleAPIError(err.response.status);
        return result;
      }
    }
  }
  return false;
}

export async function getPensionBeeFundListData() {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/get-pensionbee-funds`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          }
        }
      );
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function getAvcWiseEmployerData() {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/get-avcwise-employers`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          }
        }
      );
      if (response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function getAvcWiseFundListData() {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/get-avcwise-funds`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          }
        }
      );
      if (response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function updateUserValues(userObj) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    const dataToSend = { updateObject: userObj, uid: userObj.documentId };
    try {
      const response = await axios(`${B2B_FIREBASE_API_PREFIX}/edit-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        data: dataToSend,
        body: JSON.stringify(dataToSend)
      });
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function checkMedicalFormStatus() {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/check-medical-form-status`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          }
        }
      );
      if (response.status == 200) {
        return response.data;
      }
    } catch (err) {
      console.log(err.response);
      if (err.response.status == 503) {
        const alertMsg = err.response.data || 'something went wrong!';
        await window.$nuxt.$store.commit('setSnack', alertMsg);
        return false;
      } else {
        const result = await HandleAPIError(err.response.status);
        return result;
      }
    }
  }
  return false;
}

export async function getUserMedicalData(userId) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    const dataToSend = { id: userId };
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/get-medical-form-data`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          data: dataToSend,
          body: JSON.stringify(dataToSend)
        }
      );
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function updateMedicalFormValues(ObjToSend, userId) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    const dataToSend = { updateObject: ObjToSend, uid: userId };
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/edit-medical-form-data`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          data: dataToSend,
          body: JSON.stringify(dataToSend)
        }
      );
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function readUserNotification(notiId) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    const dataToSend = { notificaitonId: notiId };
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/read-notification`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          data: dataToSend,
          body: JSON.stringify(dataToSend)
        }
      );
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      return false;
    }
  }
  return false;
}

export async function loginUserVal(userData) {
  const dataToSend = { email: userData.email, password: userData.password };
  try {
    const response = await axios(`${B2B_FIREBASE_API_PREFIX}/login-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataToSend,
      body: JSON.stringify(dataToSend)
    });
    if (response && response.status == 200) {
      await signInWithToken(response.data);
      return true;
    }
  } catch (err) {
    // data not validated
    if (err.response && err.response.data) {
      throw err;
    }
    await logOut();
    return false;
  }
}

export async function generateLoaForPaidPensions(data) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/generate-loa-for-paid-pensions`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          data,
          body: JSON.stringify(data)
        }
      );
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      return false;
    }
  }
  return false;
}

export async function triggerSuezReportGeneration(data) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_SUEZ_API_PREFIX}/trigger-suez-report-generation`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          data,
          body: JSON.stringify(data)
        }
      );
      if (response && response.status == 200) {
        return response.data;
      }
    } catch (err) {
      return false;
    }
  }
  return false;
}

export async function createPaymentIntent(data) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/create-payment-intent`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          data: { ...data, promoCode: 'TSTCPN2P' },
          body: JSON.stringify(data)
        }
      );
      if (response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function createCheckoutSession(data) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/create-checkout-session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          data,
          body: JSON.stringify(data)
        }
      );
      if (response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function fetchCheckoutSession(data) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(
        `${B2B_FIREBASE_API_PREFIX}/fetch-checkout-session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
          },
          data,
          body: JSON.stringify(data)
        }
      );
      if (response.status == 200) {
        return response.data;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function registerUser(data) {
  try {
    const response = await axios(`${B2B_FIREBASE_API_PREFIX}/register-user`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data,
      body: JSON.stringify(data)
    });
    if (response.status == 200) {
      const result = await signInWithToken(response.data);
      return result;
    }
  } catch (err) {
    // data not validated
    if (err.response.data) {
      throw err;
    }
    await logOut();
    return false;
  }
}

export async function getSuezUserData(data) {
  const authToken = await getUserAuthToken();
  if (authToken) {
    try {
      const response = await axios(`${B2B_SUEZ_API_PREFIX}/getuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        data,
        body: JSON.stringify(data)
      });
      if (response.status == 200) {
        return response;
      }
    } catch (err) {
      const result = await HandleAPIError(err.response.status);
      return result;
    }
  }
  return false;
}

export async function HandleAPIError(errCode) {
  if (errCode == 500) {
    await sendSentryError(false);
    return false;
  }
  if (errCode == 403 || errCode == 400) {
    // data not validated
    await sendSentryError(true);
    await logOut();
    return false;
  }
}

export async function sendSentryError(isLogoutError) {
  const alertMsg = isLogoutError
    ? 'Your session has expired. Please login again to continue.'
    : 'Something wrong went. Please try again.';
  const sentryErr = isLogoutError
    ? 'User Logout Due to API Failed or Auth token Expired.'
    : 'Internal server error from API';
  await window.$nuxt.$store.commit('setSnack', alertMsg);
  Sentry.withScope(function(scope) {
    scope.setLevel('error');
    Sentry.captureException(new Error(sentryErr));
  });
}

export async function verifyAndFetchUserData(verificationData) {
  try {
    const resp = await axios.get(
      `${B2B_AVC_API_PREFIX}/checkuser?email=${encodeURIComponent(
        verificationData.email
      )}&voucher=${encodeURIComponent(verificationData.voucherCode)}`,
      {
        headers: {
          Authorization: API_AUTHORIZATION_HEADER
        }
      }
    );
    return resp;
  } catch (err) {
    console.log('error triggering signup', err);
  }
  return true;
}
